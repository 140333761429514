<template>
    <div class="card card-pdv">
        <PageHeader :title="i18nDueDate" :show-slot="false">
            <button
                type="button"
                :class="`btn btn-pdv btn-pdv-blue ${type === 'box' ? 'button-active' : ''}`"
                href="javascript: void(0);"
                :disabled="loading"
                @click="refreshChart('box')"
            >
                {{ i18nBox }}
            </button>
            <button
                type="button"
                :class="`btn btn-pdv btn-pdv-blue ${type === 'unit' ? 'button-active' : ''}`"
                href="javascript: void(0);"
                :disabled="loading"
                @click="refreshChart('unit')"
            >
                {{ i18nUnit }}
            </button>
        </PageHeader>
        <div class="card-body" style="height: 300px;">
            <column-chart
                :colors-prop="['#FD4084', '#F3D63F', '#2E7FEB']"
                :series-prop="series"
                :busy="loading"
            />
        </div>
    </div>
</template>

<script>
import DueDateService from '@src/services/dashboards/widgets/DueDateService'

import PageHeader from '@/src/components/PageHeader.vue'
import ColumnChart from '@/src/components/charts/ColumnChart.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'

const i18nKey = 'DASHBOARDS'

export default {
    components: {
        PageHeader,
        ColumnChart,
    },
    mixins: [
        validationMixin,
        swalFeedback,
    ],
    data() {
        return {
            i18nKey,
            loading: false,
            type: 'unit',
            series: [],
        }
    },
    validations: {},
    computed: {
        i18nBox() {
            return this.getI18n(i18nKey, 'TITLES.box')
        },
        i18nDueDate() {
            return this.getI18n(i18nKey, 'TITLES.due_date')
        },
        i18nUnit() {
            return this.getI18n(i18nKey, 'TITLES.unit')
        },
    },
    methods: {
        refresh(parameters) {
            this.loading = true
            this.series = []

            DueDateService.statistic(parameters)
                .then((response) => {
                    this.series.push({
                        name: this.getI18n(i18nKey, 'TYPES.within_fifteen_days_expiration'),
                        data: [response.data.data.within_fifteen_days_expiration],
                    })

                    this.series.push({
                        name: this.getI18n(i18nKey, 'TYPES.from_sixteen_to_thirty_days_expiration'),
                        data: [response.data.data.from_sixteen_to_thirty_days_expiration],
                    })

                    this.series.push({
                        name: this.getI18n(i18nKey, 'TYPES.more_thirty_days_expiration'),
                        data: [response.data.data.more_thirty_days_expiration],
                    })

                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    return err
                })
        },
        refreshChart(type) {
            this.type = type
        }
    },
}
</script>

<style scoped>
.button-active:enabled {
        color: #2e7feb;
    }
</style>
