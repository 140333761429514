import BaseService from '@src/services/BaseService'

class DueDateService extends BaseService {
    constructor() {
        super('dashboards/widgets/due-date')
    }

    resume(params) {
        return this.axios.post(`${this.getUrl()}/resume`, params)
    }

    statistic(params) {
        return this.axios.post(`${this.getUrl()}/statistic`, params)
    }
}

export default new DueDateService()

