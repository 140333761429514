<template>
    <div>
        <indicator-card
            ref="chartPosActiveForResearch"
            class="col-sm-12"
            :title="i18nPosActiveForResearch"
            :busy="loading"
            :value="posActive"
        />
        <indicator-card
            ref="chartPosSearched"
            class="col-sm-12"
            :title="i18nPosSearched"
            :busy="loading"
            :value="posSearched"
        />
        <indicator-card
            ref="chartPercentagePosSearched"
            class="col-sm-12"
            :title="i18PercentagePosSearched"
            :busy="loading"
            :value="percentagePosSearched"
            type="percentage"
        />
    </div>
</template>

<script>
import DueDateService from '@src/services/dashboards/widgets/DueDateService'

import IndicatorCard from '@/src/components/dashboards/IndicatorCard.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'

const i18nKey = 'DASHBOARDS'

export default {
    components: {
        IndicatorCard,
    },
    mixins: [
        validationMixin,
        swalFeedback,
    ],
    data() {
        return {
            i18nKey,
            loading: false,
            posActive: 0,
            posSearched: 0,
            percentagePosSearched: 0,
        }
    },
    validations: {},
    computed: {
        i18nPosActiveForResearch() {
            return this.getI18n(i18nKey, 'TITLES.pos_active_for_research')
        },
        i18nPosSearched() {
            return this.getI18n(i18nKey, 'TITLES.pos_searched')
        },
        i18PercentagePosSearched() {
            return this.getI18n(i18nKey, 'TITLES.percentage_pos_searched')
        },
    },
    methods: {
        refresh(parameters) {
            this.loading = true
            this.posActive = 0
            this.posSearched = 0
            this.percentagePosSearched = 0

            DueDateService.resume(parameters)
                .then((response) => {
                    this.posActive = response.data.data.pos_active
                    this.posSearched = response.data.data.pos_audited
                    this.percentagePosSearched = this.posActive > 0 ? Math.round(this.posSearched * 100 / this.posActive) : 0
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    return err
                })
        }
    },
}
</script>
