<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-8">
                    <b-row>
                        <b-col md="5">
                            <b-form-group
                                :label="i18nPeriod"
                                class="required label-pdv"
                                label-for="filter-date"
                            >
                                <date-range-picker
                                    :value.sync="filters.date"
                                    :max-date="new Date()"
                                    :disabled="loading"
                                    :disable-clean-button="true"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group
                                :label="i18nDueDate"
                                class="label-pdv"
                                label-for="filter-due-date"
                            >
                                <translated-multiselect
                                    id="filter-due-date"
                                    v-model="filters.due_date"
                                    :options="dueDateTypes"
                                    :disabled="loading"
                                    :preselect-first="true"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div class="col-sm-4 align-self-center">
                    <div class="d-flex justify-content-between">
                        <more-filters-label-button class="col-sm-6" :disabled="loading" @onClick="showFilterModal" />
                        <filter-button class="col-sm-6 col-md-6" :disabled="loading" @onClick="filter" />
                    </div>
                </div>
            </div>

            <b-row>
                <b-col md="4">
                    <due-date-resume ref="dueDateResume" />
                </b-col>
                <b-col md="8">
                    <due-date-chart ref="dueDateChart" />
                </b-col>
            </b-row>
            <div class="card card-pdv">
                <PageHeader :title="i18nValidity" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="fields"
                        :per-page="perPage"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="day_to_expiration" slot-scope="props">
                            <!-- eslint-disable-next-line vue/no-v-html -->
                            <div v-html="formatDayToExpiration(props.rowData.date)"></div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>

        <due-date-filter-modal ref="dueDateFilterModal" :handle-submit="filter" />
    </Layout>
</template>

<script>
import DueDateService from '@src/services/dashboards/widgets/DueDateService'

import Layout from '@layouts/main'
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import MoreFiltersLabelButton from '@/src/components/MoreFiltersLabelButton'
import FilterButton from '@/src/components/FilterButton'
import DateRangePicker from '@/src/components/DateRangePicker'
import translatedMultiselect from '@src/components/translatedMultiselect'
import dueDateFilterModal from '@src/router/views/dashboards/dueDate/dueDateFilterModal.vue'
import dueDateChart from '@src/router/views/dashboards/dueDate/dueDateChart.vue'
import dueDateResume from '@src/router/views/dashboards/dueDate/dueDateResume.vue'
import filterVuetable from '@src/mixins/filterVuetable'
import dueDateTypes from '@src/mixins/dueDateTypes'
import moment from 'moment';

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        Layout,
        ApiModeVuetable,
        Breadcrumb,
        PageHeader,
        MoreFiltersLabelButton,
        FilterButton,
        DateRangePicker,
        translatedMultiselect,
        dueDateFilterModal,
        dueDateChart,
        dueDateResume,
    },
    mixins: [
        filterVuetable,
        dueDateTypes,
    ],
    data() {
        return {
            i18nKey,
            i18nCommon,
            loading: false,
            perPage: 10,
            additionalParameters: {},
            sortOrder: [
                {
                    field: 'date',
                    direction: 'asc',
                },
            ],
            fields: [
                {
                    name: 'date_last_visit',
                    title: this.getI18n(i18nCommon, 'last_visit'),
                    sortField: 'date_last_visit',
                },
                {
                    name: 'user_name',
                    title: this.getI18n('USERS', 'TITLES.user'),
                    sortField: 'user_name',
                },
                {
                    name: 'pos_name',
                    title: this.getI18n('POS', 'TITLES.pos'),
                    sortField: 'pos_name',
                },
                {
                    name: 'address',
                    title: this.getI18n(i18nCommon, 'address'),
                },
                {
                    name: 'regional_name',
                    title: this.getI18n('POS', 'TITLES.regional'),
                    sortField: 'regional_name',
                },
                {
                    name: 'chain_name',
                    title: this.getI18n('POS', 'TITLES.chain'),
                    sortField: 'chain_name',
                },
                {
                    name: 'product_category_name',
                    title: this.getI18n('PRODUCT_CATEGORIES', 'TITLES.product_category'),
                    sortField: 'product_category_name',
                },
                {
                    name: 'product_name',
                    title: this.getI18n('PRODUCTS', 'TITLES.product'),
                    sortField: 'product_name',
                },
                {
                    name: 'date',
                    title: this.getI18n('QUESTIONS', 'SUBTYPES.due_date'),
                    sortField: 'date',
                },
                {
                    name: 'number',
                    title: this.getI18n(i18nKey, 'TITLES.amount'),
                    sortField: 'number',
                },
                {
                    name: 'day_to_expiration',
                    title: this.getI18n(i18nKey, 'TITLES.day_to_expiration'),
                    sortField: 'day_to_expiration',
                },
            ],
            filters: {
                date: {
                    start: moment().startOf('day').add(-7, 'days').toDate(),
                    end: moment().startOf('day').toDate()
                },
                due_date: null,
                business_units: [],
                regionals: [],
                chains: [],
                channels: [],
                product_categories: [],
            },
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.dashboards',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18n(i18nKey, 'TITLES.due_date_dashboard'),
                    active: true,
                },
            ]
        },
        i18nPeriod() {
            return this.getI18n(i18nCommon, 'period')
        },
        i18nDueDate() {
            return this.getI18n(i18nKey, 'TITLES.due_date')
        },
        i18nValidity() {
            return this.getI18n(i18nCommon, 'validity')
        },
    },
    mounted() {
        this.filter()
    },
    methods: {
        createResumeParameters() {
            const period = this.formatFilterDate(this.filters.date)
            return this.formatParameters({
                due_date: this.filters.due_date && this.filters.due_date.value ? this.filters.due_date.value : null,
                business_unit_id: this.filters.business_units.map((businessUnit) => businessUnit.id),
                regional_id: this.filters.regionals.map((regional) => regional.id),
                chain_id: this.filters.chains.map((chain) => chain.id),
                channel_id: this.filters.channels.map((channel) => channel.id),
                product_category_id: this.filters.product_categories,
                start: period.from,
                end: period.to,
            })
        },
        filter(appliedFilters) {
            if (appliedFilters) {
                this.filters = appliedFilters
            }
            this.additionalParameters = this.createResumeParameters()
            this.refresh()
        },
        showFilterModal() {
            this.$nextTick(() => this.$refs.dueDateFilterModal.showModal(this.filters))
        },
        fetch(url, params) {
            return DueDateService.fetchVuetable('list', params)
        },
        refresh() {
            this.loading = true

            this.$refs.dueDateResume.refresh(this.additionalParameters)
            this.$refs.dueDateChart.refresh(this.additionalParameters)
            this.refreshTable()

            this.loading = false
        },
        refreshTable() {
            this.$nextTick(() => this.$refs.vuetable.refresh())
        },
    }
}
</script>
